import React from "react";
import ReactDOM from "react-dom/client";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAZx5FQrEV9ugPxojsjByNYz-g2Vg0JaMo",
  authDomain: "app-lia.firebaseapp.com",
  projectId: "app-lia",
  storageBucket: "app-lia.appspot.com",
  messagingSenderId: "585019803318",
  appId: "1:585019803318:web:54ad79e80ef154ea680817",
  measurementId: "G-KQD5063DTN"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

function createMetaTag(property: string, content: string): HTMLMetaElement {
  const metaTag = document.createElement("meta");
  metaTag.setAttribute("property", property);
  metaTag.setAttribute("content", content);

  return metaTag;
}

function resolveLink(): void {
  const id =
    !!window && !!window.location && !!window.location.pathname
      ? window.location.pathname.substr(1)
      : "";
  db.collection("short_links")
    .doc(!!id && !!id.length ? id : "none")
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        await doc.ref.update({ views: Number(doc.data()?.views ?? 0) + 1 });

        const head = document.head;
        head.appendChild(
          createMetaTag("og:image", (doc?.data?.()?.url ?? "") as string)
        );
        head.appendChild(
          createMetaTag("og:url", (doc?.data?.()?.url ?? "") as string)
        );

        window.location.href = doc?.data?.()?.url;
      } else {
        // Redirect to google
        window.location.href = "https://ohmylia.com";
      }
    });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

resolveLink();

root.render(<React.StrictMode></React.StrictMode>);
